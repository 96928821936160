<script setup lang="ts">
import VCatalogEmpty from "@magnit/unit-catalog/src/components/VCatalogEmpty.vue";

const emit = defineEmits<{
  "click:primary": [];
  "click:secondary": [];
}>();

const links = {
  catalog: Routes.Catalog,
  promoCatalog: Routes.PromoCatalog,
};
</script>

<template>
  <VCatalogEmpty
    :url-primary-button="links.catalog"
    :url-secondary-button="links.promoCatalog"
    @click:primary="emit('click:primary')"
    @click:secondary="emit('click:secondary')"
  />
</template>
